import React, { Suspense, useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Splash from "./splash";
import Home from "./Home/Home";
import Vets from "./Vets/Vets";
import GroomingHome from "./Home/GroomingHome";
import LandingPage from "./Home/LandingPage";
import Header from "../component/Header/Header";
import { getBreedList } from "../service/api";


const AddPet = React.lazy(() => import("./AddPet/AddPet"));
const AddPetData = React.lazy(() => import("./AddPet/AddPetData"));
const PersonalDetails = React.lazy(() => import("./PersonalDetails/PersonalDetails"));
const TimeSlot = React.lazy(() => import("./TimeSlot/TimeSlot"));
const MyOrder = React.lazy(() => import("./MyOrder/MyOrder"));
const Login = React.lazy(() => import("./Login/Login"));
const Profile = React.lazy(() => import("./Profile/Profile"));
const Address = React.lazy(() => import("./Address/Address"));
const PlanConfirm = React.lazy(() => import("./PlanConfirm/PlanConfirm"));
const MyBookings = React.lazy(() => import("./MyBookings/MyBookings"));
const BookedPlan = React.lazy(() => import("./BookedPlan/BookedPlan"));
const ReturnRefundPolicy = React.lazy(() => import("./Return&Refund/ReturnRefundPolicy"));
const PrivacyPolicy = React.lazy(() => import("./PrivacyPolicy/PrivacyPolicy"));
const TernsCondition = React.lazy(() => import("./TermsCondition/TernsCondition"));
const Mypets = React.lazy(() => import("./MyPets/Mypets"));
const EditPet = React.lazy(() => import("./EditPet/EditPet"));
const ThankYou = React.lazy(() => import("./ThankYou/ThankYou"));
const Grooming = React.lazy(() => import("./Grooming/Grooming"));
const PersonalDetailsData = React.lazy(() => import("./PersonalDetails/PersonalDetailsData"));
const AddressDetails = React.lazy(() => import("./PersonalDetails/AddressDetails"));
const SummerySlot = React.lazy(() => import("./TimeSlot/SummerySlot"));
const PackageConfirmation = React.lazy(() => import("./PlanConfirm/PackageConfirmation"));
const BlogScreen = React.lazy(() => import("./Blogs/BlogScreen"));
const BlogListScreen = React.lazy(() => import("./Blogs/BlogListScreen"));
const Register = React.lazy(() => import("./Register/Register"));
const AboutUs = React.lazy(() => import("./AboutUs/AboutUs"));
const VetAtHome = React.lazy(() => import("./Vets/VetAtHome"));
const SlugChecker = React.lazy(() => import("./Blogs/SlugChecker"));
const IssuedPage = React.lazy(() => import('../pages/IssuedPages/IssuedPage'));
const BecomingPartner = React.lazy(() => import("./BecomeAPartner/BecomeAPartner"));
const RedirectionHandler = React.lazy(() => import("./Redirect/RedirectionHandler"));
const VaccinationPackage = React.lazy(() => import("./VaccinationPet/VaccinationPackage"));
const DogService = React.lazy(() => import("./DogServices/DogServices"));
const CatService = React.lazy(() => import("./CatServices/CatServices"));
const VetPackages = React.lazy(() => import("./Vets/VetPackages"));
const BookedPDF = React.lazy(() => import("./BookedPlan/BookedPDF"));
const VetPage = React.lazy(() => import("./Vets/VetPage"));
const VetPageHome = React.lazy(() => import("./Vets/VetPageHome"));
const ProductHome = React.lazy(() => import("./Products/ProductHome"));
const ProductsPage = React.lazy(() => import("./Products/ProductsPage"));
const ProductPage = React.lazy(() => import("./Products/ProductPage"));
const PersonalDetailsProduct = React.lazy(() => import("./PersonalDetails/PersonalDetailsProduct"));
const FoodProducts = React.lazy(() => import("./Products/FoodProducts"));
const FoodPage = React.lazy(() => import("./Products/Foods/FoodPage"));
const DownloadPrescription = React.lazy(() => import("./Vets/PDF/DownloadPrescription"));
const PrescriptionForm = React.lazy(() => import("./Vets/PDF/PrescriptionForm"));
const VaccineForm = React.lazy(() => import("./Vets/PDF/VaccineForm"));
const DownloadVaccine = React.lazy(() => import("./Vets/PDF/DownloadVaccine"));
const WhatsAppIcon = React.lazy(() => import("../../src/assets/icons/whatsapp3.png"));



function Main() {
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(false);



  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize); // Listen for resize events

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up on unmount
    };
  }, []);

  const showHeader = !location.pathname.includes("/address");

  useEffect(() => {
    const loginData = JSON.parse(localStorage.getItem("loggedin"));
    if (loginData) {
      checkApi();
    } else {
    }
  }, [location]);

  const checkApi = async () => {
    try {
      const checkToken = await getBreedList();

      if (checkToken.message === "Unauthenticated.") {
        localStorage.clear();
        window.location.href = "/";
      }
    } catch (error) {
      console.log("Unauthenticated", error);
    }
  };

  const renderWhatsAppButton = () => {
    if (location.pathname === "/" && isMobile) {
      <button className="whatsapp-icon-sticky">
        <a href="https://wa.me/+917599975888" target="_blank" rel="noreferrer">
          <img src={WhatsAppIcon} alt="WhatsApp" />
        </a>
      </button>;
    }
  };

  return (
    <React.Fragment>
      <RedirectionHandler />
      {showHeader && <Header />

      }
      <Suspense fallback={<div className="pt-5" style={{minHeight:'100vh'}}></div>}>
        <Routes>
          {/* <Route path='/' element={<Splash />} /> */}
          <Route path="" element={<LandingPage />} />
          <Route path="login" element={<Login />} />
          <Route path="addpet" element={<AddPet />} />
          <Route path="register" element={<Register />} />
          <Route path="grooming" element={<GroomingHome />} />
          <Route path="personal-details" element={<PersonalDetailsData />} />
          <Route path="appointment-details" element={<AddressDetails />} />
          <Route path="user-details" element={<PersonalDetailsProduct />} />
          <Route path="timeSlot" element={<TimeSlot />} />
          <Route path="myorder" element={<MyOrder />} />
          <Route path="editProfile" element={<Profile />} />
          <Route path=":city" element={<Grooming />} />
          <Route path="packages" element={<VetPackages />} />
          <Route path="vet-on-call" element={<VetPage />} />
          <Route path="vet-at-home-services" element={<VetAtHome />} />
          <Route path="products" element={<ProductHome />} />
          <Route path="products-list" element={<ProductsPage />} />
          <Route path="product/:id" element={<ProductPage />} />
          <Route path="dearpet-foods" element={<FoodProducts />} />
          <Route path="dearpet-food/:id" element={<FoodPage />} />

          {/* <Route path="blogs/:slug" element={<BlogScreen />} /> */}
          <Route path="blog/dear-pet" element={<BlogListScreen />} />
          <Route path="/blog/:slug" element={<BlogScreen />} />

          <Route path="/blog/dear-pet/:slug" element={<SlugChecker />} />

          {/* <Route path="/blog/dear-pet/:slug" element={<BlogScreen />}/> */}

          <Route path="address" element={<Address />} />
          <Route path="planconfirm" element={<PlanConfirm />} />
          <Route path="mybookings" element={<MyBookings />} />
          <Route path="bookedplan" element={<BookedPlan />} />
          <Route path="prescription" element={<BookedPDF />} />
          <Route path="vaccine" element={<BookedPDF />} />
          <Route path="rr-policy" element={<ReturnRefundPolicy />} />
          <Route path="p-policy" element={<PrivacyPolicy />} />
          <Route path="t-policy" element={<TernsCondition />} />
          <Route path="mypets" element={<Mypets />} />
          <Route path="package-confirmation" element={<PackageConfirmation />} />
          <Route path="editpet" element={<EditPet />} />
          <Route path="thankyou" element={<ThankYou />} />

          {/*  I added new links from here */}
          <Route path="aboutus" element={<AboutUs />} />
          <Route path="becoming-partner" element={<BecomingPartner />} />
          {/*this added later for vets services */}

          <Route path="vet" element={<Vets />} />
          <Route path="/dog-services" element={<DogService />} />
          <Route path="/cat-services" element={<CatService />} />
          <Route path="/vet-at-home" element={<VetPageHome />} />
          <Route path="/issue-list" element={<IssuedPage />} />
          <Route path="vaccination-package" element={<VaccinationPackage />} />

          <Route path="presc-form" element={<PrescriptionForm />} />
          <Route path="presc-down" element={<DownloadPrescription />} />
          <Route path="vac-form" element={<VaccineForm />} />
          <Route path="vac-down" element={<DownloadVaccine />} />

        </Routes>
      </Suspense>

      {renderWhatsAppButton()}
    </React.Fragment>
  );
}

export default Main;

