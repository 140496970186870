import React, { useEffect, useState } from "react";
import './VetServices.css'
import '../../Work/style.css'
import { getAddress, getCities, getDashboard, getVetCategoriesPlans } from "../../../service/api";
import { useNavigate } from "react-router-dom";





export default function VetServices() {
  const [vetPlans, setVetPlans] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCityId, setSelectedCityId] = useState(3);
  const [planLoader, setPlanLoader] = useState(false);
  const [selectedPet, setSelectedPet] = useState("Dog");
  const [isLogin, setIsLogin] = useState(false);



  useEffect(() => {
    const storedDashboardData = localStorage.getItem("dashboardData");

    if (!storedDashboardData || storedDashboardData === "{}") {
      dashboardData();
    } else {
    }
  }, []);

  const dashboardData = async () => {
    try {
      const dashboardData = await getDashboard();
      if (dashboardData.status === '200') {
        localStorage.setItem("dashboardData", JSON.stringify(dashboardData.data));
      }
    } catch (error) {
      console.error("Error in get home page data:", error);
    }
  };

  useEffect(() => {
    fetchCities();
  }, [])
  useEffect(() => {
    if (isLogin) {
      getUserAddress();
    }
  }, [isLogin]);

  useEffect(() => {
    const loginuser = JSON.parse(localStorage.getItem("loggedin"));
    if (loginuser) {
      setIsLogin(loginuser);
    }

  }, []);

  const fetchCities = async () => {
    try {
      const cityData = await getCities();

      // const sortedCities = cityData.data.sort((a, b) => a.sorting - b.sorting);

      // let addselectCity = [{ id: '-1', title: 'Select City', state_id: 13, sorting: '0' }, ...sortedCities]

      // setCities(sortedCities);
      // setCities(addselectCity);
      setCities(cityData.data);

      if (cityData.success === "1") {
        const storedItem = JSON.parse(localStorage.getItem("homeData"));
        if (storedItem) {
          setSelectedPet(storedItem.selectedPet);
        } else {
          // setSelectedCityId(cityData.data[0].id);

          // setSelectedCity(cityData.data[0]);
          // setSelectedCityId(cityData.data[0].id);
          // setSelectedPet(selectedPet);

          const data = {
            selectedCity: cityData.data[1],
            selectedCityId: cityData.data[1].id,
            selectedPet: selectedPet,
          };

          // const data = {
          //   selectedCity: addselectCity[0],
          //   selectedCityId: addselectCity[0].id,
          //   selectedPet: selectedPet,
          // };

          // Store the default values in localStorage
          localStorage.setItem("homeData", JSON.stringify(data));

          // Retrieve stored item and update state
          const storedItem = JSON.parse(localStorage.getItem("homeData"));
          setSelectedPet(storedItem.selectedPet);
          setSelectedCityId(storedItem.selectedCityId);

          // Call onCityChange with the desired data structure

        }
      }
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  const getUserAddress = async () => {
    try {
      const addressData = await getAddress();
      if (addressData?.data?.length > 0) {
        setSelectedCityId(addressData.data[0].city_id);

        const mycityData = {
          id: addressData.data[0].id,
          state_id: addressData.data[0].state_id,
          title: addressData.data[0].city_name,
        };

        let userIdData = JSON.parse(localStorage.getItem("userIdData"));
        userIdData = {
          ...userIdData,
          selectedCity: mycityData,
          city: addressData.data[0].city_name,
          selectedCityId: addressData.data[0].city_id,
        };

        localStorage.setItem("userIdData", JSON.stringify(userIdData));

        const data = {
          selectedCity: mycityData,
          selectedCityId: addressData.data[0].city_id,
          selectedPet: selectedPet,
        };
        localStorage.setItem("homeData", JSON.stringify(data));
        const storedItem = JSON.parse(localStorage.getItem("homeData"));
        setSelectedPet(storedItem.selectedPet);
        setSelectedCityId(storedItem.selectedCityId);
      } else {
      }
    } catch (error) {
      console.log("city get address error", error);
    }
  };



  const withoutLoginChangeCity = (event) => {
    const selectedCityId = event.target.value;
    const selectedCity = cities.find((city) => city.id === selectedCityId);

    setSelectedCityId(selectedCityId);

    const data = { selectedCity, selectedCityId, selectedPet };
    localStorage.setItem("homeData", JSON.stringify(data));
    const storedItem = JSON.parse(localStorage.getItem("homeData"));
    setSelectedPet(storedItem.selectedPet);
    setSelectedCityId(storedItem.selectedCityId);

  };




  useEffect(() => {
    if (selectedCityId) {
      fetchPlans();
    }
  }, [selectedCityId]);

  const fetchPlans = async () => {
    try {
      const bodySend = { city_id: selectedCityId };

      const planData = await getVetCategoriesPlans(bodySend);

      if (planData.success === "1" && planData.data.length > 0) {
        // const filteredPlans = planData.data.filter(plan => plan.category_id != 3);

        // Sort the filtered plans
        const sortedPlans = planData.data.sort((a, b) => {
          return a.is_coming_soon === b.is_coming_soon ? 0 : a.is_coming_soon ? 1 : -1;
        });

        setVetPlans(sortedPlans); // Set the sorted plans
        setPlanLoader(false);
      } else {
        setVetPlans([]); // If no plans available, set an empty array
        setPlanLoader(true); // Show loader if no plans are available
      }
    } catch (error) {
      console.error("Error fetching vet product plans:", error);
      setPlanLoader(true); // Show loader or error message
    }
  };

  // ending here

  return (
    <div className="work-container">
      <h2 className="font-extrabold landing-services-title col mb-0">Veterinary Services</h2>

      {/* <div className="city-selection">
        <h3 className="city-title">Please select your city</h3>
        <div className="select-container">
          <select
            onChange={withoutLoginChangeCity}
            value={selectedCityId || ""}
            className="city-select"
          >
            
            {cities.map((city) => (
              <option key={city.id} value={city.id}>
                {city.title}
              </option>
            ))}
          </select>
        </div>
      </div> */}
      <div className="selectCityPart">
        <h2 className="sec2Headingcity mb-1">Please select your city</h2>
        <select
          // className={`cityDropdown1 ${inputDisable ? "muteInput" : ""}`}
          className="cityDropdown1"
          onChange={withoutLoginChangeCity}
          value={selectedCityId}
          style={
            { minWidth: '300px' }
          }
          aria-label="Select a city"
        >
          {cities.map((city) =>
            city.id == -1 ? (
              <option key={city.id} value={city.id} disabled={true}>
                {city.title}
              </option>
            ) : (
              <option key={city.id} value={city.id}>
                {city.title}
              </option>
            )
          )}
        </select>
      </div>

      {/* Other component logic */}

      <div className="services-container mt-4">
        <div className="vets-services-grid">
          {planLoader ? (
            <p>Loading services...</p>
          ) : vetPlans.length > 0 ? (
            vetPlans.map((plan, index) => (
              <VetServiceCard
                key={index}
                plan={plan}
                selectedCity={selectedCityId}

              />
            ))
          ) : (
            <></>
          )}
        </div>
      </div>

    </div>
  );
}

const VetServiceCard = ({ plan, selectedCity }) => {
  const navigate = useNavigate();


  const handleBookNow = () => {

    if (Array.isArray(plan.category_id) && plan.category_id.includes(3)) {
      // localStorage.setItem("selectedPlan", JSON.stringify(plan.product));
      // navigate("/issue-list");
      navigate("/vet-on-call", { state: { catId: plan.category_id, petType: plan.type, selectedCityId: selectedCity } });
    } else if (!plan.is_product) {
      localStorage.setItem("categoryId", JSON.stringify(plan.category_id));
      navigate(`/packages`, {
        state: { catId: plan.category_id, petType: plan.type, selectedCityId: selectedCity, planTitle: plan.title }
      });
    } else {
      localStorage.setItem("selectedPlan", JSON.stringify(plan.product));
      const loginuser = JSON.parse(localStorage.getItem("loggedin"));
      const naviData = { fromPage: "vet" };

      if (loginuser) {
        navigate("/mypets", { state: { fromWhich: naviData } });
      } else {
        navigate("/personal-details", { state: { fromWhich: naviData } });
      }
    }

  };

  return (
    <div
      className={`vets-service-card ${!plan.is_coming_soon ? "clickable" : ""}`}
      onClick={() => !plan.is_coming_soon ? handleBookNow() : {}}
    >
      <div className="vets-service-image-container">
        {plan.image && (
          <img
            src={plan.image}
            alt={plan.title}
            className={`vets-service-image ${!plan.is_coming_soon ? "zoom-on-hover" : ""}`}
          />
        )}

        {plan.is_coming_soon && (
          <div className="coming-soon-overlay">
            <div className="coming-soon-text">Coming Soon</div>
          </div>
        )}
      </div>
      <h3 className="vets-service-title">{plan.title}</h3>
    </div>
  );

}