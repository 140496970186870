import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './landing.css'; // Import the CSS file
import LandingBanner from '../../component/Landing/LandingBanner';
import LandingServices from '../../component/Landing/LandingServices';
import CoverPhoto from './CoverPhoto';
import WhyChooes from './WhyChooes';
import VideoSlider from './VideoSlider';
import HomeLandingPage from './HomeLandingPage';
import Celebrating from './Celebrating';
import NitinStory from './NitinStory';
import { getDashboard } from '../../service/api';
import LandingProduct from '../../component/Landing/LandingProduct';
import FoodMeal from '../Products/Foods/FoodMeal';
import LandingCities from '../../component/Landing/LandingCities';
import Review from './Review';
import LandingInfo from '../../component/Landing/LandingInfo';

const TestiMonialsVideo = lazy(() => import('./TestimonialsVideo'));

const LandingPage = () => {
  const [reviewData, setReviewData] = useState([]);

  useEffect(() => {
    window.scroll(0, 0);
    dashboardData();
  }, [])

  const dashboardData = async () => {
    try {
      const dashboardData = await getDashboard();
      if (dashboardData.status === '200') {
        localStorage.setItem("dashboardData", JSON.stringify(dashboardData.data))
        setReviewData(dashboardData.data.reviews);
      }
    } catch (error) {
      console.error("Error in get home page data:", error);
    }
  };

  return (
    <div className="landing-container">
      <LandingBanner />
      <WhyChooes />
      <LandingInfo />
      <NitinStory />
      <FoodMeal />
      <Celebrating />
      <Review reviewData={reviewData} />
      <Suspense fallback={null}>
        <TestiMonialsVideo />
      </Suspense>
      <LandingCities />
      <HomeLandingPage />
    </div>
  );
};

export default LandingPage;
