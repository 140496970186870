import React from 'react'
import StressFree from '../../../assets/icons/stressfree.svg';
import Personalize from '../../../assets/icons/cat-lover.svg';
import Attention from '../../../assets/icons/search-person.svg';

import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/swiper-bundle.css";
import '../../BenefitAtHome/BenefitsOfVetAtHome.css';

function WhyChoose() {
    const chooseData = [
        {
            id:1,
            icon: StressFree,
            title: "Stress-Free Experience",
            description:"Whether it's an at-home visit or a video consultation, your pet remains in a familiar and comfortable environment, reducing stress."
        },
        {
            id:2,
            icon: Personalize,
            title: "Personalize Attention",
            description:" Every pet is unique. Our vets provide individualized care, focusing on your pet's specific needs with one-on-one attention."
        },
        {
            id:3,
            icon: Attention,
            title: "Trusted by Pet Parents",
            description:" Thousands of pet parents trust DearPet for our ethical approach, transparent communication, and commitment to quality care."
        },
    ];
    return (
        <div className="container mt-0 benefits-container whychoosemb-container">
            <h1 className="benefits-heading">Why Choose DearPet?</h1>
            <Swiper
                slidesPerView="auto" // Enables fixed-width slides
                spaceBetween={20} // Adjusts the space between slides
                className="mySwiper"
            >
                {chooseData.map((slid) => (
                    <SwiperSlide key={slid.id} style={{ width: '300px' }}>
                        <SliderItem slider={slid} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}

const SliderItem = ({ slider, index }) => {
    return (
        <div className="benefit-card">
            <img src={slider.icon} alt={slider.title} className="benefit-icon"  />
            <h3>{slider.title}</h3>
        </div>
    );
};

export default WhyChoose