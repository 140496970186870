import React from "react";
import "./landingpage.css";
import { Link } from "react-router-dom";
import ReadMore from "./ReadMore";


const HomeLandingPage = ({ city, handleBookNow, plan }) => {
  return (
    <div className="petGroomingContainer">
      <Link
        className="mainHeading"
        to={"/mypets"}
        onClick={() => handleBookNow(plan)}
      >
        Book Grooming Appointment
      </Link>

      <div>
        <div className="section">
          <div className="subsection">
            <h2 className="subHeading flex">
              Discover the Best Pet Grooming Services Near You with DearPet
            </h2>
            <div>
              <p>

              Welcome to DearPet, where we Treat Your Loyals like Royals. If
              you're searching for the best pet grooming near me, further! Our
               professional team offers top-notch grooming services
              for both dogs and cats, ensuring your furry friends always look
              and feel Read more. 
              </p>
              ....<span className="flex justify-center text-2xl"><ReadMore/></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeLandingPage;
