import React from "react";
import { Swiper, SwiperSlide  } from "swiper/react";
import { Pagination } from "swiper/modules";

import "./style.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";



// SwiperCore.use([Navigation]);
const Gallery = () => {
  const gallery = [
    {
      id: 1,
      before: 'https://dearpet.co/public/uploads/assets/before-after/before1.webp',
      after: 'https://dearpet.co/public/uploads/assets/before-after/after1.webp',
    },
    {
      id: 2,
      before: 'https://dearpet.co/public/uploads/assets/before-after/before2.webp',
      after: 'https://dearpet.co/public/uploads/assets/before-after/after2.webp',
    },
    {
      id: 3,
      before: 'https://dearpet.co/public/uploads/assets/before-after/before3.webp',
      after: 'https://dearpet.co/public/uploads/assets/before-after/after3.webp',
    },
    {
      id: 4,
      before: 'https://dearpet.co/public/uploads/assets/before-after/before4.webp',
      after: 'https://dearpet.co/public/uploads/assets/before-after/after4.webp',
    },
    {
      id: 5,
      before: 'https://dearpet.co/public/uploads/assets/before-after/before5.webp',
      after: 'https://dearpet.co/public/uploads/assets/before-after/after5.webp',
    },
  ];

  return (
    <div className="container my-2 mt-4">
      <h4 className="gallery-heading text-center">Gallery</h4>
      <div className="row mt-3">
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 1,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 2,
              spaceBetween: 50,
            },
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          {gallery.map((myimg) => {
            return (
              <SwiperSlide key={myimg.id}>
                <div className="galleryImgBody">
                  <div className="text-overlay txtbefore">
                    <p className="overlay-text">BEFORE</p>
                  </div>
                  <img src={myimg.before} alt="before" className="gallryImg beforeimg" loading="lazy" />
                  <div className="text-overlay txtafter">
                    <p className="overlay-text">AFTER</p>
                  </div>
                  <img src={myimg.after} alt="after" className="gallryImg afterimg" loading="lazy" />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default Gallery;
