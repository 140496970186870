import React from 'react';
import LeftSideImage from '../../../assets/images/vet-dog-care.webp';
import RightSideImage from '../../../assets/images/howitwork.webp';
import './HowItWorks.css'; // Create this CSS file for styling

function HowItWorks() {
  return (
    <div className=" how-it-works-container">
      <img src={LeftSideImage} alt="Vet Dog Care" className="work-left-image"  />
      <img src={RightSideImage} alt="How It Works" className="work-right-image"  />
    </div>
  );
}

export default HowItWorks;
