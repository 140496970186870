import React from "react";
import SplashIcon from "../../assets/icons/splash-purple.svg";
import LineCelebrate from "../../assets/icons/lines-celebrate.svg";
import CatIcon from "../../assets/icons/cat-icon.svg";
import DogIcon from "../../assets/icons/dog-icon.svg";
// import <FontAwesomeIcon icon={faPlus} />

function Celebrating() {
  return (
    <div className="celebrating-container mt-4 mb-5">
      <h4>
        <span className="celebrating-text">Celebrating</span>
        <br></br>
        <span className="success-text"> Our Success</span>
      </h4>
      <div className="success-divider"></div>
      <div className="stat-celebrate">
        <h5 className="parent-container">
          35000{
            " "
          }
          <span className=""> +</span>
        </h5>
        <span>Pets groomed</span>
      </div>
      <div className="stat-celebrate">
        <h5 className="parent-container">
          450 {" "}
          <span className=""> +</span>
        </h5>
        <span>5 star review</span>
      </div>
      <div className="stat-celebrate">
        <h5 className="parent-container">
          100 {" "}
          <span className=""> +</span>
        </h5>
        <span>Professional groomer</span>
      </div>
      <div className="splash-celebrate">
        <img src={SplashIcon} alt="splash" />
      </div>
      <br />
      <div className="line-celebrate">
        <img src={LineCelebrate} alt="celebrate" />
      </div>
      <div className="dog-celebrate">
        <img src={DogIcon} alt="dog" />
      </div>
      <div className="cat-celebrate">
        <img src={CatIcon} alt="cat" />
      </div>
    </div>
  );
}

export default Celebrating;
