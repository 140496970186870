import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Pagination, Autoplay } from "swiper/modules";


import image1 from "../../assets/loadingImages/img1.webp";
import image2 from "../../assets/loadingImages/img2.webp";
import image3 from "../../assets/loadingImages/img3.webp";
import image4 from "../../assets/loadingImages/img4.webp";
import image5 from "../../assets/loadingImages/img5.webp";

import "swiper/css";
import "swiper/css/pagination";

SwiperCore.use([Pagination, Autoplay]);

const VideoSlider = () => {
  const [activeVideoIndex, setActiveVideoIndex] = useState(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(Array(5).fill(false));

  const Images = [
    {
      id: 0,
      video: "https://dearpet.co/public/uploads/video/video3.mp4",
      thumbnail: image1,
    },
    {
      id: 1,
      video: "https://dearpet.co/public/uploads/video/video2.mp4",
      thumbnail: image2,
    },
    {
      id: 2,
      video: "https://dearpet.co/public/uploads/video/video5.mp4",
      thumbnail: image3,
    },
    {
      id: 3,
      video: "https://dearpet.co/public/uploads/video/video4.mp4",
      thumbnail: image4,
    },
    {
      id: 4,
      video: "https://dearpet.co/public/uploads/video/video1.mp4",
      thumbnail: image5,
    },
  ];


  const handleVideoButtonClick = (index) => {
    if (activeVideoIndex !== null && activeVideoIndex !== index) {
      const prevVideo = document.getElementById(`video-${activeVideoIndex}`);
      prevVideo.pause();
      setIsVideoPlaying((prevState) => {
        const newState = [...prevState];
        newState[activeVideoIndex] = false;
        return newState;
      });
    }

    const videoElement = document.getElementById(`video-${index}`);
    if (videoElement.paused) {
      videoElement.play();
      setIsVideoPlaying((prevState) => {
        const newState = [...prevState];
        newState[index] = true;
        return newState;
      });
      setActiveVideoIndex(index);
    } else {
      videoElement.pause();
      setIsVideoPlaying((prevState) => {
        const newState = [...prevState];
        newState[index] = false;
        return newState;
      });
      setActiveVideoIndex(null);
    }
  };

  return (
    <div className="container my-3 myVideoSlider">
      <p className="sec5SubHeading pawHeadingPc text-start mb-4">
        Thoughtful curations <br />
        Of our{" "}
        <span className="finest">
          <span className="font-bold">finest</span>
        </span>{" "}
        experiences
      </p>
      <Swiper
        slidesPerView={"auto"}
        spaceBetween={20}
        pagination={{ clickable: true }}
        className="mySwiper"
      >
        {Images.map((myimg, index) => (
          <SwiperSlide key={index}>
            <div className="video-wrapper">
              <video
                id={`video-${index}`}
                src={myimg.video}
                className="myvideo"
                loop={true}
                style={{
                  display: activeVideoIndex === index ? "block" : "none",
                }}
              />
              {activeVideoIndex !== index && (
                <img
                  src={myimg.thumbnail}
                  alt={`Thumbnail ${index}`}
                  className="thumbnail"
                  loading="lazy"
                  style={{
                    display: activeVideoIndex === index ? "none" : "block",
                  }}
                />
              )}
              <button
                className="playButton"
                onClick={() => handleVideoButtonClick(index)}
                aria-label="play"
              >
                <i
                  className={`${isVideoPlaying[index] ? "pause-icon" : "play-icon"
                    }`}
                >
                  {isVideoPlaying[index] ? (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="white" // Set the color to white
                    >
                      <path d="M6 6h4v12H6zm8 0h4v12h-4z" /> {/* Pause Icon */}
                    </svg>
                  ) : (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="white" // Set the color to white
                    >
                      <path d="M8 5v14l11-7z" /> {/* Play Icon */}
                    </svg>
                  )}
                </i>

              </button>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default VideoSlider;
