import React, { lazy, Suspense, useEffect, useRef } from "react";


import HealthCheckUp from "../HealthCheckUp/HealthCheckUp";
import StarVets from "../StarVets/StarVets";

import "./homestyle.css";
import BannerPage from "../BannerPage/BannerPage";
import WhyChoose from "./Components/WhyChoose";
import HowItWorks from "./Components/HowItWorks";
import HighlightPoints from "./Components/HighlightPoints";
import HighlightPointsV2 from "./Components/HighlightPointsV2";
import VetServices from "./Components/VetServices";
import WhyChoosePc from "./Components/WhyChoosePc";

const TestimonialSlider = lazy(() => import("./TestimonialSlider"));

export default function Vets() {
  const ourServicesRef = useRef(null);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const scrollToOurServices = () => {
    if (ourServicesRef.current) {
      ourServicesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };


  return (
    <div style={{ backgroundColor: '#f5f5f5' }}>
      <BannerPage onButtonClick={scrollToOurServices} />
      <div ref={ourServicesRef}>
        <VetServices onCityChange={() => { }} />
      </div>
      <HealthCheckUp />
      <WhyChoose />
      <WhyChoosePc />
      <HowItWorks />
      <HighlightPoints />
      <HighlightPointsV2 />
      <StarVets />
      <br />
      <Suspense fallback={null}>
        <TestimonialSlider />
      </Suspense>
    </div>
  );
}
