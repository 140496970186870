import React from "react";
import ExprtiseImg from "../../assets/images/expertise.svg";
import TrustImg from "../../assets/images/trust.svg";
import QualityImg from "../../assets/images/quality.svg";
import ConvenienceImg from "../../assets/images/convenience.svg";
import CommitmentImg from "../../assets/images/commitment.svg";
// import Services from "../../assets/images/services.webp";
import "./style.css";

const WhyChooes = () => {
  return (
    <div className="why-choose-section">

      <div className="why-choose-container">
        {/* Left side: Services Image */}
        <div className="why-choose-left">
          <img src={'https://dearpet.co/public/uploads/assets/services.webp'} alt="Services" className="servicesImg" loading="lazy" />
        </div>

        {/* Right side: Why Choose DearPet content */}
        <div className="why-choose-right">
          <h3 className="whyChooesHeading mb-0">
            Why Choose <span style={{ color: '#F29A4C' }}>DearPet?</span>
          </h3>
          <div className="why-choose-wrapper">
            <div className="why-choose-details">
              <div className="WC-grid">
                <div className="WC-row">
                  <div className="leftIconImg">
                    <img src={ExprtiseImg} alt="Expertise" loading="lazy" />
                  </div>
                  <div className="rightContent">
                    <h5 className="m-0 WR-Top">Expertise :</h5>
                    <p className="m-0 WR-Bottom">20 years in the pet care industry</p>
                  </div>
                </div>
                <div className="WC-row">
                  <div className="leftIconImg">
                    <img src={QualityImg} alt="Quality" loading="lazy" />
                  </div>
                  <div className="rightContent">
                    <h5 className="m-0 WR-Top">Quality :</h5>
                    <p className="m-0 WR-Bottom">
                      <a
                        className="whychooesItemLink"
                        href="https://bit.ly/3XR36c1"
                        target="_blank"
                        rel="noreferrer"
                      >
                        4.9/5 star rating on Google
                      </a>
                    </p>
                  </div>
                </div>
                <div className="WC-row">
                  <div className="leftIconImg">
                    <img src={TrustImg} alt="Trust" loading="lazy" />
                  </div>
                  <div className="rightContent">
                    <h5 className="m-0 WR-Top">Trust :</h5>
                    <p className="m-0 WR-Bottom">
                      Rigorous background checks and training for all professionals
                    </p>
                  </div>
                </div>
                <div className="WC-row">
                  <div className="leftIconImg">
                    <img src={ConvenienceImg} alt="Convenience" loading="lazy" />
                  </div>
                  <div className="rightContent">
                    <h5 className="m-0 WR-Top">Convenience :</h5>
                    <p className="m-0 WR-Bottom">
                      At-home services tailored to your pet's needs
                    </p>
                  </div>
                </div>
                <div className="WC-row">
                  <div className="leftIconImg">
                    <img src={CommitmentImg} alt="Commitment" loading="lazy" />
                  </div>
                  <div className="rightContent">
                    <h5 className="m-0 WR-Top">Commitment :</h5>
                    <p className="m-0 WR-Bottom">Over 10,000 pets groomed and counting</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default WhyChooes;
