import React from "react";
import "./style.css";
import { Link } from "react-router-dom";

// import coverphoto from "../../assets/images/cover_photo.webp";


function CoverPhoto({ onCoverPhotoClick, city, handleBookNow, plan }) {
  

  return (
    <div className="cover-photo-container" onClick={onCoverPhotoClick}>
      <img src={'https://dearpet.co/public/uploads/assets/cover_photo.webp'} alt="Cover" className="cover-photo" />

      <div className="cover-photo-text-container">
        <div className="cover-heading-text">
          <h1 className="cover-photo-text-cur">Premium At-Home</h1>
          <span className="cover-photo-text-cur">Pet Grooming</span>
        </div>


        <Link
          className="cover-photo-btn"
          to={"/mypets"}
          onClick={() => handleBookNow(plan)}
        >
          Book Appointment
        </Link>
      </div>
    </div>
  );
}

export default CoverPhoto;
