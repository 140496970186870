
import './style.css';

export default function BannerPage({onButtonClick}) {
  return (
    <>
      <div className="banner-container" >
        <div className="banner-content">
          <h1>Vet Care at Your Doorstep – Convenient, Ethical, Transparent</h1>
          <p>
          Expert vet care, at home or online – stress-free and trusted.
          </p>
        </div>
          <button className="banner-button" onClick={onButtonClick} aria-label="Book a Vet Visit Today" role='button' >Book a Vet Visit Today</button>
      </div>
    </>
  );
}
